import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';

import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';

import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { CampaignFilters } from '../interfaces';

@Component({
  selector: 'iml-campaign-filters',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatChipsModule,
  ],
  providers: [
    MatDatepickerModule,
    DatePipe,
    DecimalPipe,
    provideNativeDateAdapter(),
  ],
  templateUrl: './campaigns-filters.component.html',
  styleUrl: './campaigns-filters.component.scss'
})
export class CampaignsFiltersComponent implements OnInit {
  @Input() from = new Date('2023-01-01');
  @Input() to = new Date();

  @Output() filtersUpdated = new EventEmitter<CampaignFilters>();

  filterForm = new FormGroup({
    range: new FormGroup({
      start: new FormControl<Date | null>(this.from),
      end: new FormControl<Date | null>(this.to),
    }),
    status: new FormControl<string[]| null>([]),
    type: new FormControl<string[]| null>([]),
  });

  filterData: CampaignFilters = {
    from: this.from,
    to: this.to,
    filters: {},
  };

  constructor() {}

  ngOnInit(): void {
    this.filtersUpdated.emit(this.filterData);

    this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      if (value.range?.start && value.range?.end) {
        this.filterData.from = value.range.start;
        this.filterData.to = value.range.end;
      }

      if (value.status) {
        this.filterData.filters.status = value.status;
      } else {
        delete this.filterData.filters.status;
      }

      if (value.type) {
        this.filterData.filters.type = value.type;
      } else {
        delete this.filterData.filters.type;
      }

      this.filtersUpdated.emit(this.filterData);
    });
  }

  resetFilters() {
    this.filterForm.setValue({
      range: {
        start: this.from,
        end: this.to,
      },
      status: [],
      type: [],
    });
  }
}
