<div style="text-align: right;">
  <button (click)="resetFilters()" mat-flat-button>
    Reset All
    <mat-icon>refresh</mat-icon>
  </button>
</div>
<!-- <div class="filter-header">
  <mat-icon fontSet="material-symbols-outlined">filter_alt</mat-icon>
  <span class="mat-body-strong">Presets</span>
</div>
<iml-sidenav-menu [links]="presets" /> -->
<div class="filter-header">
  <mat-icon fontSet="material-symbols-outlined">tune</mat-icon>
  <span class="mat-body-strong">Customize</span>
</div>
<form [formGroup]="filterForm">
  <div style="margin-top: 5px; margin-bottom: 0px;">
    <mat-form-field appearance="outline">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input formGroupName="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      @if (filterForm.controls.range.controls['start'].hasError('matStartDateInvalid')) {
        <mat-error>Invalid start date</mat-error>
      }
      @if (filterForm.controls.range.controls['end'].hasError('matEndDateInvalid')) {
        <mat-error>Invalid end date</mat-error>
      }
    </mat-form-field>
  </div>
  <!-- <div class="filter-content">
    <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
      <span style="flex: 1;">Insertion Order</span>
      <mat-slide-toggle color="primary" style="flex: 0 0 auto;" />
    </div>
  </div> -->
  <div class="filter-header">
    <mat-icon fontSet="material-symbols-outlined">switch_access_2</mat-icon>
    <span class="mat-body-strong">Creative Type</span>
  </div>
  <div class="filter-content">
    <mat-chip-listbox formControlName="type" multiple aria-label="Type">
      <mat-chip-option>Creative</mat-chip-option>
      <mat-chip-option>RFP</mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div class="filter-header">
    <mat-icon fontSet="material-symbols-outlined">page_info</mat-icon>
    <span class="mat-body-strong">Creative Status</span>
  </div>
  <div class="filter-content">
    <mat-chip-listbox formControlName="status" multiple aria-label="Status">
      <mat-chip-option>New</mat-chip-option>
      <mat-chip-option>Released</mat-chip-option>
      <mat-chip-option>Declined</mat-chip-option>
    </mat-chip-listbox>
  </div>
</form>
