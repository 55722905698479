import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { SidenavMenuComponent, SidenavMenuLink } from '../../core/components/sidenav/sidenav-menu/sidenav-menu.component';
import { SidenavContainerComponent } from '../../core/components/sidenav/sidenav-container/sidenav-container.component';
import { Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { fadeInOutPage } from '../../animations';
import { UserLogoComponent } from '../../shared/user-logo/user-logo.component';
import { AssetType, Creative, CreativeRequestStatus, CreativeRequestType, CreativesService } from './creatives.service';
import { SortOrder } from '../campaigns/campaigns.service';
import { MenuService } from '../../core/services/menu.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ScreenService } from '../../core/services/screen.service';
import { AdditionalCreativeFilters, CreativeFilters } from './interfaces';
import { CreativesFiltersComponent } from './creatives-filters/creatives-filters.component';

@Component({
  selector: 'iml-creatives',
  standalone: true,
  imports: [
    SidenavMenuComponent,
    SidenavContainerComponent,
    CommonModule,
    MatSidenavModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    UserLogoComponent,
    CreativesFiltersComponent,
  ],
  templateUrl: './creatives.component.html',
  styleUrl: './creatives.component.scss',
  animations: [fadeInOutPage],
})
export class CreativesComponent {
  CreativeRequestType = CreativeRequestType;
  CreativeRequestStatus = CreativeRequestStatus;
  AssetType = AssetType;

  opened = true;
  openInProgress = false;
  inProgress = false;

  isLoadingProgress = false;
  scrollPosition = 0;

  displayedColumns: string[] = [
    'submitted_by',
    'name',
    'type',
    'submitted_at',
    'status',
    'assets',
  ];
  dataSource = new MatTableDataSource<Creative>(); // CAMPAIGNS
  nextPage?: string;

  sortBy: string | null = null;
  sortOrder = SortOrder.Desc;
  defaultFrom = new Date('2023-01-01');
  defaultTo = new Date();
  from = this.defaultFrom;
  to = this.defaultTo;

  filters: AdditionalCreativeFilters = {};
  // range = new FormGroup({
  //   start: new FormControl<Date | null>(this.from),
  //   end: new FormControl<Date | null>(this.to),
  // });

  // presets: SidenavMenuLink[] = [
  //   { name: 'All assets', path: './all' },
  //   { name: 'New assets', path: './new' },
  //   { name: 'RFPs', path: './rfps' },
  //   { name: 'Creatives', path: './creatives' },
  //   { name: 'Containing Banners', path: './banners' },
  //   { name: 'Containing Drivers', path: './drivers' },
  // ];

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private router: Router,
    private menu: MenuService,
    public screen: ScreenService,
    private data: CreativesService,
  ) {}

  async ngOnInit() {

    this.menu.pageMenuToggled.subscribe(() => this.toggleMenu());
  }

  async ngAfterViewInit() {
    this.dataSource.sort = this.sort;

    // this.data.assets(
    this.isLoadingProgress = true;
    this.dataSource.data = await this.getAssets(this.from, this.to);
    this.isLoadingProgress = false;
  }

  async reloadData(data: CreativeFilters) {
    delete this.nextPage;

    this.from = data.from;
    this.to = data.to;
    this.filters = data.filters;

    if (this.sortBy) {
      this.dataSource.data = await this.getAssets(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
    } else {
      this.dataSource.data = await this.getAssets(this.from, this.to, this.filters);
    }
  }

  async getAssets(
    from: Date,
    to: Date,
    filters: any = null,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
  ) {
    this.inProgress = true;
    const { creatives, next } = await this.data.assets(from, to, sortBy, sortOrder, filters, this.nextPage as any);
    this.inProgress = false;
    this.nextPage = next;

    return creatives;
  }

  async checkScroll(event: any) {
    const { scrollTop, scrollHeight, offsetHeight } = event.srcElement;
    this.scrollPosition = scrollTop;
    if (!this.inProgress) {
      const scrollValue = (scrollHeight - scrollTop) / offsetHeight;
      if (scrollValue < 1.2) {
        const assets = await this.getAssets(this.from, this.to);
        this.dataSource.data = this.dataSource.data.concat(assets);
      } else if (scrollValue < 2) {
        this.isLoadingProgress = true;
      } else {
        this.isLoadingProgress = false;
      }
    }
  }

  async sortData(sortState: Sort) {
    const sortMap: { [key: string]: string } = {
      submittedBy: 'submitted_by',
      startDate: 'start_date',
      endDate: 'end_date',
    };
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
    let creatives: Creative[];

    delete this.nextPage;
    if (sortState.direction === SortOrder.Asc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Asc;
      creatives = await this.getAssets(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
    } else if (sortState.direction === SortOrder.Desc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Desc;
      creatives = await this.getAssets(this.from, this.to, this.filters, this.sortBy, this.sortOrder);
    } else {
      creatives = await this.getAssets(this.from, this.to);
    }

    console.log('CREATIVES', creatives);
    if (creatives) {
      this.dataSource.data = creatives;
    }
  }

  getRouteAnimationState() {
    console.log('WOW', this.router.url);
    return this.router.url; // getRouteAnimationState(this.route);
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
  }

  onMenuMouseEnter() {
    console.log('MOUSE ENTER');
  }

  onMenuMouseLeave() {
    console.log('MOUSE LEAVE');
  }

  async toggleMenu() {
    // this.openInProgress = true;
    // await this.sidenav.toggle();
    this.opened = !this.opened;
    this.menu.isPageMenuOpened.next(this.opened);

    // this.openInProgress = false;
  }
}
