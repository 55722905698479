export const environment = {
  // API_PATH: 'https://api.secureiml.com',
  // PORTAL_API_PATH: 'http://localhost:3623', // 'https://portal-api.secureiml.com', // 'http://localhost:3623', //
  // AUTH_PATH: 'https://auth.secureiml.com',
  API_PATH: 'https://api-staging.secureiml.com',
  PORTAL_API_PATH: 'https://portal-stage-api.secureiml.com',
  AUTH_PATH: 'https://auth-staging.secureiml.com',
  CLIENT_ID: '583aabca0f3ca3709af75f4515fa2035089eb53fb75ad86bd7e9272354552ee8198faede7c484bbb1aaf61bdfbf2cad8100cdca4e3b6db47a00ef97c35b4620e',
  CODE_VERIFIER: 'iml-code-verifier',
  AUTH_TOKEN: 'iml-token',
  AUTH_USER: 'iml-user',
};
