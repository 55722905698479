import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { PORTAL_API_PATH } from '../../../constants';
import { CampaignOkResponse, SortOrder } from '../campaigns/campaigns.service';
import { CreativeSearchOkResponse, ResourcePageSearchOkResponse, TypeformSearchOkResponse } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private httpClient: HttpClient) { }

  async campaigns(
    query: string,
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
    filters?: any,
    next?: string,
  ) {
    const url = `${PORTAL_API_PATH}/campaigns/search`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().appendAll({
        q: query,
        from_date: from.toISOString(),
        to_date: to.toISOString(),
      })
    };

    console.log('SORT?????', sortBy, sortOrder);
    if (sortBy) {
      httpOptions.params = httpOptions.params
        .append('sort_by', sortBy)
        .append('order_by', sortOrder);
    }

    if (filters) {
      httpOptions.params = httpOptions.params
        .append('filters', JSON.stringify(filters)); // TODO: fix stringify issue
    }

    if (next) {
      httpOptions.params = httpOptions.params
        .append('from', next);
    }

    const campaignSource$ = this.httpClient.get<CampaignOkResponse>(url, httpOptions);
    const response = await firstValueFrom(campaignSource$);

    return response;
  }

  async getCreatives(
    query: string,
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
    filters?: any,
    next?: string,
  ) {
    const url = `${PORTAL_API_PATH}/creatives/search`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().appendAll({
        q: query,
        from_date: from.toISOString(),
        to_date: to.toISOString(),
      })
    };

    console.log('SORT?????', sortBy, sortOrder);
    if (sortBy) {
      httpOptions.params = httpOptions.params
        .append('sort_by', sortBy)
        .append('order_by', sortOrder);
    }

    if (filters) {
      httpOptions.params = httpOptions.params
        .append('filters', JSON.stringify(filters)); // TODO: fix stringify issue
    }

    if (next) {
      httpOptions.params = httpOptions.params
        .append('from', next);
    }

    const campaignSource$ = this.httpClient.get<CreativeSearchOkResponse>(url, httpOptions);
    const response = await firstValueFrom(campaignSource$);

    return response;
  }

  async getResources(query: string, next?: string) {
    const url = `${PORTAL_API_PATH}/resources/search`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().appendAll({
        q: query,
      })
    };

    if (next) {
      httpOptions.params = httpOptions.params
        .append('from', next);
    }

    const campaignSource$ = this.httpClient.get<ResourcePageSearchOkResponse>(url, httpOptions);
    const response = await firstValueFrom(campaignSource$);

    return response;
  }

  async getForms(query: string, next?: string) {
    const url = `${PORTAL_API_PATH}/forms/search`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: new HttpParams().appendAll({
        q: query,
      })
    };

    if (next) {
      httpOptions.params = httpOptions.params
        .append('from', next);
    }

    const campaignSource$ = this.httpClient.get<TypeformSearchOkResponse>(url, httpOptions);
    const response = await firstValueFrom(campaignSource$);

    return response;
  }
}
