import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';

import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MenuService } from '../../core/services/menu.service';
import { fadeInOutPage, getRouteAnimationState } from '../../animations';
import { UserLogoComponent } from '../../shared/user-logo/user-logo.component';

import { Creative, CreativeRequestType, CreativeRequestStatus, AssetType } from '../creatives/creatives.service';
import { CommonModule } from '@angular/common';
import { SearchService } from './search.service';
import { Campaign, CampaignStatus, SortOrder } from '../campaigns/campaigns.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchCreative, SearchResourcePage, SearchTypeformQuestion } from './interfaces';

@Component({
  selector: 'iml-search',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatChipsModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    UserLogoComponent,
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  animations: [fadeInOutPage],
})
export class SearchComponent implements OnInit, AfterViewInit {
  section = 'campaigns';
  inProgress = false;
  isLoadingProgress = false;

  CampaignStatus = CampaignStatus;
  CreativeRequestType = CreativeRequestType;
  CreativeRequestStatus = CreativeRequestStatus;
  AssetType = AssetType;
  displayedColumns: string[] = [
    'submittedBy',
    'name',
    'status',
    'startDate',
    'endDate',
    'type',
    'impressions',
    'clicks',
    'ctr',
    'vtc',
  ];
  dataSource = new MatTableDataSource<Campaign>(); // CAMPAIGNS);
  // resources: SearchResource[] = [];

  displayedColumns2: string[] = [
    'submitted_by',
    'name',
    'type',
    'submitted_at',
    'status',
    'assets',
  ];
  dataSource2 = new MatTableDataSource<Creative>(); // CAMPAIGNS

  // displayedColumns: string[] = [
  //   'submittedBy',
  //   'name',
  //   'type',
  //   'date',
  //   'status',
  //   'updatedAt',
  //   'assets',
  // ];
  // dataSource = new MatTableDataSource(CAMPAIGNS);

  query!: string;
  sortBy: string | null = null;
  sortOrder = SortOrder.Desc;
  from = new Date('2022-01-01');
  to = new Date();
  // range = new FormGroup({
  //   start: new FormControl<Date | null>(this.from),
  //   end: new FormControl<Date | null>(this.to),
  // });

  currentTab: 'campaigns'|'creatives'|'resources'|'forms' = 'campaigns';

  campaigns: {
    list: any[],
    next?: string,
  } = {
    list: [],
  };
  creatives: {
    list: SearchCreative[],
    next?: string,
  } = {
    list: [],
  };
  resources: {
    list: SearchResourcePage[],
    next?: string,
  } = {
    list: [],
  };
  forms: {
    list: SearchTypeformQuestion[],
    next?: string,
  } = {
    list: [],
  };

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private menu: MenuService,
    private service: SearchService,
  ) {}

  ngOnInit(): void {
    this.menu.isPageMenuOpened.next(false);
    this.route.queryParams
      .subscribe(async (params: Params) => {
        console.log('params', params['q']);
        this.query = params['q'];
        this.inProgress = true;

        await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
        // this.dataSource.data = campaigns;
        // this.resources = resources;
        // this.inProgress = false;
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  async fetchSearchResults(
    query: string,
    from: Date,
    to: Date,
    sortBy: string | null = null,
    sortOrder = SortOrder.Desc,
  ) {
    try {
      this.inProgress = true;
      delete this.campaigns.next;
      delete this.creatives.next;
      delete this.resources.next;
      delete this.forms.next;
      this.dataSource.data = [];
      this.dataSource2.data = [];
      this.sortBy = null;
      this.sortOrder = SortOrder.Desc;

      switch (this.currentTab) {
        case 'creatives':
          const { creatives, next } = await this.service.getCreatives(query, from, to, sortBy, sortOrder);

          this.creatives = {
            list: creatives,
            next: next,
          };
          this.dataSource2.data = this.creatives.list as any[];
          break;
        case 'resources':
          const { resourcePages, next: next2 } = await this.service.getResources(query);
          this.resources = {
            list: resourcePages,
            next: next2,
          };
          break;
        case 'forms':
          const { questions, next: next3 } = await this.service.getForms(query);

          this.forms = {
            list: questions,
            next: next3,
          };
          break;
        case 'campaigns':
        default:
          const response = await this.service.campaigns(query, from, to, sortBy, sortOrder);

          this.campaigns.list = response.campaigns.map(c => {
            let company = null;

            c.name.replace(/(^|\s)(ANN|KDRV|KEZI|KIMT|WAAY|WFFT|WLFI|WTHI|WTVA|KIAH|KDAF|WLOV|KADN|WEVV|KITV|WSIL|WXOW|WQOW|WKOW|KWWL|WREX|WAOW|KVOA|WJRT|WCOV)(\s|$)/gi, function (match) {
              company = match.toUpperCase();

              return match;
            });

            if (company) {
              c.submittedBy = { company } as any;
            }

            return c;
          });
          this.campaigns.next = response.next;
          this.dataSource.data = this.campaigns.list;
      }

      this.inProgress = false;
    } catch (error) {
      console.log('SEARCH ERROR', error);

      throw error;
    }
  }

  sanitize(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  async sortData(sortState: Sort) {
    const sortMap: { [key: string]: string } = {
      submittedBy: 'submitted_by',
      startDate: 'start_date',
      endDate: 'end_date',
    };
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
    let campaigns: Campaign[];

    this.inProgress = true;
    if (sortState.direction === SortOrder.Asc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Asc;
      await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
    } else if (sortState.direction === SortOrder.Desc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Desc;
      await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
    } else {
      await this.fetchSearchResults(this.query, this.from, this.to);
    }

    // console.log('CAMPAIGNS', campaigns);
    // if (campaigns) {
    //   this.dataSource.data = campaigns;
    // }
    // this.inProgress = false;
  }

  async sortData2(sortState: Sort) {
    const sortMap: { [key: string]: string } = {
      submittedBy: 'submitted_by',
      startDate: 'start_date',
      endDate: 'end_date',
    };
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
    let creatives: Creative[];

    if (sortState.direction === SortOrder.Asc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Asc;
      await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
    } else if (sortState.direction === SortOrder.Desc) {
      this.sortBy = sortMap[sortState.active] ?? sortState.active;
      this.sortOrder = SortOrder.Desc;
      await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
    } else {
      await this.fetchSearchResults(this.query, this.from, this.to);
    }

    // console.log('CREATIVES', creatives);
    // if (creatives) {
    //   this.dataSource.data = creatives;
    // }
  }

  async selectSection(section: string) {
    // this.section = section;
    console.log('WHAAYY');
    this.currentTab = (section as any);
    await this.fetchSearchResults(this.query, this.from, this.to, this.sortBy, this.sortOrder);
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log('Sorting is happening...', sortState);
  }

  getRouteAnimationState() {
    // console.log('WOW', this.route.snapshot);
    return this.router.url; // getRouteAnimationState(this.route);
  }

  getSectionAnimationState() {
    return this.section;
  }
}
